<template>
  <div class="realName">
    <div v-show="addbankCardFlag">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="用户姓名" prop="userName">
          <el-input
            v-model="ruleForm.userName"
            placeholder="请输入您的姓名"
            :readonly="!!identityData.name"
            name="userName"
          ></el-input>
        </el-form-item>
        <el-form-item label="证件类型" prop="idCardType">
          <el-input v-if="identityData.idCardType" :value="allCardType[identityData.idCardType||'1']"
                    placeholder="请选择证件类型"
                    :readonly="true"></el-input>
          <el-select :disabled="idCardTypeDisabled" v-else  v-model="ruleForm.idCardType" placeholder="请选择证件类型">
            <el-option
              v-for="item in cardType"
              :key="item.label"
              :label="item.label"
              :disabled="item.disabled"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="证件号码" prop="cardId" class="marginB">
          <el-input
            v-model="ruleForm.cardId"
            placeholder="请输入您的证件号码"
            :readonly="!!identityData.idCard"
            name="cardId"
          ></el-input>
        </el-form-item>
        <button class="comfig" type="button" @click="next('ruleForm')">下一步</button>
      </el-form>
    </div>
  </div>
</template>
<script>
import basic from '../utils/basic';
import { encryptRSA, decryptRSA, encryptAES, decryptAES } from "../utils/encryption";
import { queryUserIsRealName,realNameVerified} from "../services/api";
import $ from 'jquery'
let Base64 = require('js-base64').Base64;

export default {
  data() {
    return {
      addbankCardFlag: true,
      userNature: "",
      ruleForm: {
        idCardType: null,
        userName: "",
        userNameFlag: false,
        cardId: "",
        cardIdFlag: false,
      },
      timestampStr: "",
      identityData: {},
      idCardTypeDisabled:false,
    };
  },
  created: function() {
    var _this = this;
    //判断是否实名认证
      _this.getIdMsg();

  },
  methods: {
    async bankInfo() {
      
    },
   async getIdMsg() {//初始化接口
      const _this = this;
       const datas = await queryUserIsRealName({ });
         if (datas.res_code === 1) {
          let encrypted = datas.data.encrypted;
          console.log(encrypted)
          let data = datas.data.msg;
          let aseKey = decryptRSA(encrypted); //RSA解密
          let res = JSON.parse(decryptAES(data, aseKey)); //AES解密
          console.log(res);
          _this.userNature = res.userNature;
          //如果有实名验证信息，用实名验证信息，不能修改
          if (res.name) {
            _this.ruleForm.userName = res.name;
            _this.ruleForm.userNameFlag = true;
          }
          if (res.idCard) {
            _this.ruleForm.cardId = res.idCard;
            _this.ruleForm.cardIdFlag = true;
          }
          if (res.idCardType) {
            _this.ruleForm.idCardType = res.idCardType.toString();
            _this.idCardTypeDisabled=true;
          }
          // if (!res.idCard && !_this.$route.query.notResidentIDCard) {
          //   _this.$router.replace('/');
          // }
      } else {
        this.$toast(res_msg);
      }
     
    },
    next(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var _this = this;
          var cardId = _this.ruleForm.cardId;
          console.log(basic.checkIdCard(cardId));
          if (_this.ruleForm.idCardType == 1 && basic.checkIdCard(cardId) == false) {
            basic.toast("请输入正确的身份证号");
            return false;
          }
          _this.realNameVerifiedFun();//下一步接口

        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    async realNameVerifiedFun(){//下一步接口
       const _this = this;
        //加密串
        var timestamp = Date.parse(new Date()) + basic.generateMixed(15);
        var timestampStr = Base64.encode(timestamp);
        // RSA加密
        var aseKeyNew = encryptRSA();
        //第二个参数 requestData
        let dataV = {
          userNature: _this.userNature,//用户类型：1-个人，2-企业
          name: _this.ruleForm.userName,//
          idCardType: _this.ruleForm.idCardType,
          idCard: _this.ruleForm.cardId,
          chnlCode: 0//来源渠道
        };
        console.log(dataV);

        //AES加密
        var data2 = encryptAES(dataV);
        let sendData = {};
        sendData.msg = data2;
        sendData.encrypted = aseKeyNew;
        
      var jsencrypt = new JSEncrypt();
      jsencrypt.setPublicKey(this.key);
      const datas = await realNameVerified({chnl_code:1,timestamp:timestampStr,data:sendData});
        if (datas.res_code === 1) {  
          _this.$router.replace({
              path: "resultReal",
              query: { }
            });
        }
    },
  },  
  computed: {
    rules() {
      let rule = {
        userName: [{ required: true, message: "请输入用户姓名", trigger: "blur" }],
        idCardType: [{ required: true, message: "请选择证件类型", trigger: "change" }],
       
        cardId: [{ required: true, message: "请输入证件号码", trigger: "blur" }],
      };
      if (!this.$route.query.notResidentIDCard) {
        rule.cardId.push({ pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: "请输入正确的身份证号" });
      }
      return rule;
    },
    cardType() {
      let opt = [
        {
          value: "1",
          label: "身份证",
          disabled: !!this.$route.query.notResidentIDCard
        },
        // {
        //   value: "3",
        //   label: "中国护照"
        // },
        // {
        //   value: "6",
        //   label: "台湾来往大陆通行证"
        // },
        // {
        //   value: "7",
        //   label: "澳门来往大陆通行证"
        // },
        // {
        //   value: "8",
        //   label: "香港来往大陆通行证"
        // }
      ];
      return opt;
    }
  }
};
</script>
<style scoped lang="scss">
.realName {
  .el-input__inner {
    padding: 0 8px;
    box-sizing: border-box;
  }
.el-select-dropdown__item{height: 3.4rem;}
.el-select-dropdown__wrap{max-height: 27.4rem;}
  .cardAdrrOpt {
    width: 100%;
    box-sizing: border-box;
    display: inline-block;
  }
}
</style>
